import React, { useState } from "react";
import api from "../services/api";

function RegisterAdmin(){
    const [user, setUser] = useState();

    async function handleSubmitRegister(e){
        e.preventDefault();
        try{
            const register = await api.post('/create/user/adminmultilago/register', user); 
        } catch(error){
            console.log(error)
        }
    }

    function handleChangeRegister(e){
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }

    return(
        <div className="sec-all-container">
            <div className="sec-principal-container">
                <form onSubmit={handleSubmitRegister}>
                    <div className="sec-title">
                        <h1>admin page register</h1>
                    </div>

                    <div className="sec-fields">
                        <label>Email</label>
                        <input type="text" name="email" placeholder="Email" onChange={handleChangeRegister}/>
                    </div>

                    <div className="sec-fields">
                        <label>Senha</label>
                        <input type="password" name="password" placeholder="Senha" onChange={handleChangeRegister}/>
                    </div>
                    <hr/>
                    <div className="sec-fields">
                        <button>Registrar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RegisterAdmin;