import React from "react";
import './style.css'


function NonPage(){
    return(
        <div> Error 404: página não existe</div>
    )
}

export default NonPage;