import React, {useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import api from '../services/api.js'
import './style.css'

function Emprestimo() {
    const [form, setForm] = useState({
        nome: null,
        email: null, 
        telefone: null,
        cidade: null,
        cpf: null,
        assunto: null,
        marca: null,
        ano: null,
        modelo: null,
        entrada: null,
        valor: null,
        prestacoes: null,
    });
    const navigate = useNavigate();
    const [email, setEmail] = useState();
    const termosMaliciosos = ['!', 'onError=', 'alert(', '<script>', '</script>', 'onload=', 'asd', 'kmsd', 'asmd', 'aijd', 'oakz', 'awek', 'kkk', 'kakskd', '<script', '<scri'];
    const termoEmail = ['@'];
    const [errorVisible, setErrorVisible] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);

    
    // criptografia cpf;
    const [cpfCriptografado, setCpfCriptografado] = useState("");
    const [mudarCodif, setMudarCodif] = useState(true);
    const secretKey = process.env.REACT_APP_SECRETKEY

    // criptografia telefone;
    const[telefoneCripto, setTelefoneCripto] = useState("");
    const[mudarTelCodif, setMudarTelCodif] = useState(true);
    const secretKeyTel = process.env.REACT_APP_SECRETKEYTEL

    // criptografia para email;
    const [emailCripto, setEmailCripto] = useState("")
    const[mudarEmailCodif, setMudarEmailCodif] = useState(true);
    const secretKeyMail = process.env.REACT_APP_SECRETKEYMAIL


    // limite de requisições
    const [limiteAtingido, setLimiteAtingido] = useState(false);
 

    useEffect(() => {
        setMudarCodif(true);
    }, [form.cpf]);

    async function encryptData(text, key) {
        const encoder = new TextEncoder();
        const data = encoder.encode(text);
        
        // Converte a chave para 32 bytes (256 bits), necessário para AES-256
        const cryptoKey = await crypto.subtle.importKey(
            'raw',
            encoder.encode(key),
            { name: 'AES-CBC' },
            false,
            ['encrypt']
        );
    
        const iv = crypto.getRandomValues(new Uint8Array(16)); // IV de 16 bytes para AES-CBC
        const encryptedData = await crypto.subtle.encrypt(
            { name: 'AES-CBC', iv },
            cryptoKey,
            data
        );
    
        // Combina o IV e os dados criptografados
        const encryptedArray = new Uint8Array(iv.byteLength + encryptedData.byteLength);
        encryptedArray.set(iv, 0);
        encryptedArray.set(new Uint8Array(encryptedData), iv.byteLength);
    
        // Codifica o resultado em base64 de forma segura
        return btoa(String.fromCharCode.apply(null, encryptedArray));
    }
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let cpfToSend = form.cpf;
            let telToSend = form.telefone; 
            let emailToSend = form.email
            // Criptografa apenas se ainda não foi criptografado nesta sessão
            if (mudarCodif) {
                cpfToSend = await encryptData(form.cpf, secretKey)
                setCpfCriptografado(cpfToSend); // Armazena o valor criptografado
                setMudarCodif(false); // Marca como criptografado para evitar recriptografia
            } else {
                cpfToSend = cpfCriptografado; // Usa o CPF já criptografado
            }

            
            if(mudarTelCodif){
                telToSend = await encryptData(form.telefone, secretKey)
                setTelefoneCripto(telToSend);
                setMudarTelCodif(false);
            } else{
                telToSend = telefoneCripto;
            }

            if(mudarEmailCodif){
                emailToSend = await encryptData(form.email, secretKey);
                setEmailCripto(emailToSend);
                setMudarEmailCodif(false);
            }else{
                emailToSend = emailCripto;
            }
            
            const formToSend = { ...form, cpf: cpfToSend, telefone: telToSend, email: emailToSend };
            
            // Enviar formulário com CPF criptografado

            var formularioValido = middlewareLenght(formToSend);
            const enviarFormulario = await fetch(`${process.env.REACT_APP_FORM}`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(formToSend)
            })

            if(enviarFormulario.ok) {
                setFormSuccess(true);
            } else {
                setErrorVisible(true);
            }
        } catch (error) {
            if (error.response && error.response.data.failed) {
                setLimiteAtingido(true); // Mostra uma mensagem de limite de requisições
                setTimeout(() => {
                    setLimiteAtingido(false);
                }, 3000);
            } else {
                setMudarEmailCodif(true);
                setMudarCodif(true);
                setMudarTelCodif(true);
                setErrorVisible(true); // Exibe mensagem de erro genérico
                setTimeout(() => {
                    setErrorVisible(false);
                }, 3000);
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value
        }));

        // Se o CPF for alterado, reseta a criptografia
        if (name === "cpf") {
            setMudarCodif(true);
            setCpfCriptografado(""); // Reseta o valor criptografado
        }

        if(name === "telefone"){
            setMudarTelCodif(true); // permite a mudança da criptografia
            setTelefoneCripto(""); // Reseta o valor criptofrafado
        }

        if(name === 'email'){
            setMudarEmailCodif(true);
            setEmailCripto("");
        }
    };

    function middlewareLenght(lista){
        for(var key in lista){
            switch(key){
                case 'nome':
                    if(lista[key].length > 45){
                        throw new Error('muitos caracteres')
                    }
                    if(lista[key].length === 0 || lista[key].length < 2){
                        throw new Error("Campo não pode estar em branco")
                    }
                    if(termosMaliciosos.some(termos => lista[key].includes(termos))){
                        throw new Error('Caracter inválido')
                    }
                    break;

                case 'cidade':
                    if(lista[key].length > 100){
                        throw new Error('muitos caracteres')
                    }
                    if(lista[key].length === 0 || lista[key].length < 2){
                        throw new Error("Campo não pode estar em branco")
                    }
                    if(termosMaliciosos.some(termos => lista[key].includes(termos))){
                        throw new Error('Caracter inválido')
                    }
                    break;

                case 'email':
                    if(lista[key].length > 1000){
                        throw new Error('muitos caracteres')
                    }
                    if(lista[key].length === 0 || lista[key].length < 2){
                        throw new Error("Campo não pode estar em branco")
                    }
                    if(termosMaliciosos.some(termos => lista[key].includes(termos))){
                        throw new Error('Caracter inválido')
                    }
                    if(termoEmail.some(termos => lista[key].includes(termos))){
                        throw new Error("email invalido")
                    }
                    break;
                
                case 'telefone':
                    if(lista[key].length > 1000){
                        throw new Error('muitos caracteres')
                    }
                    if(lista[key].length === 0 || lista[key].length < 2){
                        throw new Error("Campo não pode estar em branco")
                    }
                    if(termosMaliciosos.some(termos => lista[key].includes(termos))){
                        throw new Error('Caracter inválido')
                    }
                    break;

                case 'assunto':
                    if(lista[key].length > 1000){
                        throw new Error('muitos caracteres')
                    }
                    if(lista[key].length === 0 || lista[key].length < 2){
                        throw new Error("Campo não pode estar em branco")
                    }
                    if(termosMaliciosos.some(termos => lista[key].includes(termos))){
                        throw new Error('Caracter inválido')
                    }
                    break;

                case 'marca':
                    if(lista[key].length > 50){
                        throw new Error('muitos caracteres')
                    }
                    if(lista[key].length === 0 || lista[key].length < 2){
                        throw new Error("Campo não pode estar em branco")
                    }
                    if(termosMaliciosos.some(termos => lista[key].includes(termos))){
                        throw new Error('Caracter inválido')
                    }
                    break;

                case 'cpf':
                    if(lista[key].length > 1000){
                        throw new Error('muitos caracteres')
                    }
                    if(lista[key].length === 0 || lista[key].length < 11 || lista[key].length < 14){
                        throw new Error("Campo não pode estar em branco")
                    }
                    if(termosMaliciosos.some(termos => lista[key].includes(termos))){
                        throw new Error('Caracter inválido')
                    }
                    break;


                case 'modelo':
                    if(lista[key].length > 15){
                        throw new Error('muitos caracteres')
                    }
                    if(lista[key].length === 0 || lista[key].length < 2){
                        throw new Error("Campo não pode estar em branco")
                    }
                    if(termosMaliciosos.some(termos => lista[key].includes(termos))){
                        throw new Error('Caracter inválido')
                    }
                    break;


                case 'prestacoes':
                    if(lista[key].length > 50){
                        throw new Error('muitos caracteres')
                    }
                    if(lista[key].length === 0 || lista[key].length < 2){
                        throw new Error("Campo não pode estar em branco")
                    }
                    if(termosMaliciosos.some(termos => lista[key].includes(termos))){
                        throw new Error('Caracter inválido')
                    }
                    break;
                

                 case 'entrada':
                    if(lista[key].length > 50){
                        throw new Error('muitos caracteres')
                    }
                    if(lista[key].length === 0 || lista[key].length < 2){
                        throw new Error("Campo não pode estar em branco")
                    }
                    if(termosMaliciosos.some(termos => lista[key].includes(termos))){
                        throw new Error('Caracter inválido')
                    }
                    break;
                
                 case 'valor':
                    if(lista[key].length > 50){
                        throw new Error('muitos caracteres')
                    }
                    if(lista[key].length === 0 || lista[key].length < 2){
                        throw new Error("Campo não pode estar em branco")
                    }
                    if(termosMaliciosos.some(termos => lista[key].includes(termos))){
                        throw new Error('Caracter inválido')
                    }
                    break;

                case 'ano':
                    if(lista[key].length > 50){
                        throw new Error('muitos caracteres')
                    }
                    if(lista[key].length === 0 || lista[key].length < 2){
                        throw new Error("Campo não pode estar em branco")
                    }
                    if(termosMaliciosos.some(termos => lista[key].includes(termos))){
                        throw new Error('Caracter inválido')
                    }
                    break;

                    
            }
        }
        
    }

    function errorForm() {
        setErrorVisible(true); // Exibe o erro
        setMudarCodif(false);
      }
    
      function noneErrorForm() {
        setFormSuccess(false);
        setErrorVisible(false); // Esconde o erro
        setLimiteAtingido(false);
      }

    return(
        <div className="emp-all-containers">
            <div className="emp-container-centralize">
                <div className="emp-info-pessoal">
                    <form onSubmit={handleSubmit}>
                        <h3>FORMULÁRIO PARA SIMULAÇÃO DE FINANCIAMENTO</h3>

                        <h3>DADOS PESSOAIS</h3>

                        <ul>
                            <li>
                                <label>Nome:</label>
                                <input type="text" name="nome" placeholder="Nome" onChange={handleChange} onClick={noneErrorForm}></input>
                            </li>

                            <li>
                                <label>Email:</label>
                                <input type="text" name="email" placeholder="Email" onChange={handleChange} onClick={noneErrorForm}></input>
                            </li>

                            <li>
                                <label>Telefone:</label>
                                <input type="text" name="telefone" placeholder="Telefone" onChange={handleChange} onClick={noneErrorForm}></input>
                            </li>

                            <li>
                                <label>CPF</label>
                                <input type="text" name="cpf" placeholder="cpf" onChange={handleChange} onClick={noneErrorForm}></input>
                            </li>

                            
                            <li>
                                <label>Cidade:</label>
                                <input type="text" name="cidade" placeholder="Cidade" onChange={handleChange} onClick={noneErrorForm}></input>
                            </li>

                            <li>
                                <label>Assunto Principal:</label>
                                <input type="text" name="assunto" placeholder="Assunto" onChange={handleChange} onClick={noneErrorForm}></input>
                            </li>
                        </ul>

                        <h3>VEÍCULO DESEJADO</h3>

                        <ul>
                            <li>
                                <label>Marca:</label>
                                <input type="text" name="marca" placeholder="Marca" onChange={handleChange} onClick={noneErrorForm}></input>
                            </li>

                            <li>
                                <label>Modelo:</label>
                                <input type="text" name="modelo" placeholder="Modelo" onChange={handleChange} onClick={noneErrorForm}></input>
                            </li>

                            <li>
                                <label>Ano:</label>
                                <input type="text" name="ano" placeholder="Ano" onChange={handleChange} onClick={noneErrorForm}></input>
                            </li>

                            <li>
                                <label>Valor:</label>
                                <input type="text" name="valor" placeholder="Valor" onChange={handleChange} onClick={noneErrorForm}></input>
                            </li>

                            <li>
                                <label>Valor de Entrada:</label>
                                <input type="text" name="entrada" placeholder="Valor de Entrada" onChange={handleChange} onClick={noneErrorForm}></input>
                            </li>

                            <li>
                                <label>Quantidade de Prestações:</label>
                                <input  type="text" name="prestacoes" placeholder="Quantidade de Prestações" onChange={handleChange} onClick={noneErrorForm}></input>
                            </li>

                        </ul>



                        <button>ENVIAR PEDIDO DE SIMULAÇÃO</button>
                    </form>
                    {errorVisible && (<div><span className="form-error">Erro ao enviar o formulário, tenha certeza que todos os campos estão preenchidos e sem caracteres excessivos</span></div>)}
                    {formSuccess && (<div><span className="form-success">Formulário enviado com sucesso, aguarde que retornaremos!</span></div>)}
                    
                </div>


                <div className="emp-info-750px">
                    <form onSubmit={handleSubmit}>
                        <h3>FORMULÁRIO PARA SIMULAÇÃO DE FINANCIAMENTO</h3>

                        <h3>DADOS PESSOAIS</h3>

                        <div className="info-pessoais-750px">
                            
                            
                                <div className="info-dados-pessoais-750px">
                                    <div className="info-dados-pessoais-esquerda-750px">
                                        <ul>
                                            <li>
                                                <label>NOME</label>
                                                <input type="text" name="nome" placeholder="Nome" onChange={handleChange} onClick={noneErrorForm}></input>
                                            </li>

                                            <li>
                                                <label>TELEFONE</label>
                                                <input type="text" name="telefone" placeholder="Telefone" onChange={handleChange} onClick={noneErrorForm}></input>
                                            </li>

                                            <li>
                                                <label>CPF</label>
                                                <input type="text" name="cpf" placeholder="cpf" onChange={handleChange} onClick={noneErrorForm}></input>
                                            </li>
                                        </ul>

                                    </div>
                                    <div className="info-dados-pessoais-direita-750px">
                                        <ul>
                                            <li>
                                                <label>EMAIL</label>
                                                <input type="text" name="email" placeholder="Email" onChange={handleChange} onClick={noneErrorForm}></input>
                                            </li>

                                            <li>
                                                <label>CIDADE</label>
                                                <input type="text" name="cidade" placeholder="Cidade" onChange={handleChange} onClick={noneErrorForm}></input>
                                            </li>

                                            <li>
                                                <label>ASSUNTO PRINCIPAL</label>
                                                <input type="text" name="assunto" placeholder="Assunto Principal" onChange={handleChange} onClick={noneErrorForm}></input>
                                            </li>

                                        </ul>
                                    </div>

                                </div>                            
                        

                        </div>

                        <h3>VEÍCULO DESEJADO</h3>

                        


                        <div className="info-carro-750px">
                        <div className="info-dados-pessoais-750px">
                                    <div className="info-dados-pessoais-esquerda-750px">
                                        <ul>
                                            <li>
                                                <label>MARCA</label>
                                                <input type="text" name="marca" placeholder="Marca" onChange={handleChange} onClick={noneErrorForm}></input>
                                            </li>

                                            <li>
                                                <label>MODELO</label>
                                                <input type="text" name="modelo" placeholder="Modelo" onChange={handleChange} onClick={noneErrorForm}></input>
                                            </li>

                                            <li>
                                                <label>VALOR</label>
                                                <input type="text" name="valor" placeholder="Valor" onChange={handleChange} onClick={noneErrorForm}></input>
                                            </li>
                                        </ul>

                                    </div>
                                    <div className="info-dados-pessoais-direita-750px">
                                        <ul>
                                            <li>
                                                <label>ANO</label>
                                                <input type="text" name="ano" placeholder="Ano" onChange={handleChange} onClick={noneErrorForm}></input>
                                            </li>

                                            <li>
                                                <label>VALOR DE ENTRADA</label>
                                                <input type="text" name="entrada" placeholder="Valor de Entrada" onChange={handleChange} onClick={noneErrorForm}></input>
                                            </li>

                                            <li>
                                                <label>QUANTIDADE DE PRESTAÇÕES</label>
                                                <input type="text" name="prestacoes" placeholder="Prestações" onChange={handleChange} onClick={noneErrorForm}></input>
                                            </li>

                                        </ul>
                                    </div>
                                    
                                </div>



                        

                        </div>
                        <button>ENVIAR PEDIDO DE SIMULAÇÃO</button>
                    </form>
                    {errorVisible && (<div><span className="form-error">Erro ao enviar o formulário, tenha certeza que todos os campos estão preenchidos e sem caracteres excessivos</span></div>)}
                    {formSuccess && (<div><span className="form-success">Formulário enviado com sucesso, aguarde que retornaremos!</span></div>)}
                    {limiteAtingido && (<div><span className="form-error">Limite de requisições atingido, espere uns minutos para tentar novamente</span></div>)}

                    <div className="info-preencher-espaco"></div>
                </div>
            </div>
        </div>
    )
}

export default Emprestimo;

/**
                
                
                
                
                
                <form onSubmit={handleSubmit}>

            <div className="all-containers-center">

                <div className="titleForm">
                    <div className="titleCenter">
                         <h1>Formulário Empréstimo</h1>
                    </div>
                </div>

                <div className="info-formulario">
                    <div className="nomeForm">
                        <label>Nome</label>
                        <input type="text" name="nome" placeholder="ex: Carlos" onChange={handleChange}/>
                    </div>

                    <div className="sobrenomeForm">
                        <label>Sobrenome</label>
                        <input type="text" name="sobrenome" placeholder="ex: Ribeiro Santos" onChange={handleChange}/>

                    </div>

                    <div className="emailForm">
                        <label>Email</label>
                        <input type="text" name="email" placeholder="ex: emailExemplo@gmail.com" onChange={handleChange}/>

                    </div>

                    <div className="phoneForm">
                        <label>Telefone / whatsapp</label>
                        <input type="text" name="telefone" placeholder="ex: 19 999999999" onChange={handleChange}/>

                    </div>

                    <div className="assuntoForm">
                        <label>Assunto</label>
                        <textarea name="assunto" placeholder="ex: Pedido de empréstimo" onChange={handleChange}/>

                    </div>

                    {errorVisible && (<div className="failed-form">
                        <h1>Formulario Inválido</h1>
                        <div className="failed-form-centralized-content">
                            <p>1º obverse se há caracteres restritos exemplo: !, $, &</p>
                            
                            <p>2º limite de caracteres excedidos</p>
                            <p>3º Campos inválidos (em branco, ou menos de 3 caracteres)</p>
                            

                            <button onClick={noneErrorForm}>Entendi!</button>
                        </div>
                        

                    </div>)}

                    <div className="buttonForm">
                        <button>Enviar</button>
                    </div>
                </div>

                <div className="only-color"></div>

                    
                
            </div>
            </form>
           */