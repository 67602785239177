import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/auth";
import lapis from '../assets/lapis.png'
import lupa from '../assets/lupa.png'
import './style.css';
import api from "../services/api";

function Estoque(){
    const {auth} = useContext(AuthContext);
    const [editando, setEditando] = useState(false);
    const isUserLoggedIn = auth && !!auth.token
    const [infoCarro, setInfoCarro] = useState({
        marca: '',
        modelo: '',
        motor: '',
        combustivel: '',
        carroceria: '',
        kilometragem: '',
        preco: '',
        ano: '',
        cor: '',
        opcionais: '',

    });
    const [carros, setCarros] = useState([]);
    const [filtros, setFiltros] = useState({
        marcas: null,
        ordenarPorPreco: null,
        ordenarPorComb: null
    });
    const [imagens, setImagens] = useState([]);


    const[carregandoCarros, setCarregandoCarros] = useState(false);
    const[errorCarrosCarregados, setErrorCarrosCarregados] = useState(false)
    

    useEffect(() => {
        const fetchCarro = async () => {
            try{
                const resultado = await fetch(`${process.env.REACT_APP_CARROS_DISPONIVEL}`, {
                    method: "GET",
                    headers: {"Content-Type": "application/json"},
                })
                .then((response) => response.json())
                .then((data) => {
                    setCarregandoCarros(true);
                    setCarros(data.carros);  // Já recebe os carros com as imagens
                })
                .catch((error) => { console.log("error", error); });
    
            } catch(err){
                setCarregandoCarros(true);
                setErrorCarrosCarregados(true);
            }
        }
    
        fetchCarro()
    }, [])

    const [imgCar, setImgCar] = useState({
        imagemPrincipal: null,
        imagem2: null,
        imagem3: null,
        imagem4: null,
        imagem5: null,
        imagem6: null,

    });

    function middlewareLenghtInfoCarros(list){
        for(var key in list){
            if(list[key].length === 0){
                console.log("O campo de: ", list[key], "está vazia" )
            }
        }
    }

    function handleChangeTipoCarro(e){
        setFiltros({
            ...filtros,
            [e.target.name]: e.target.value
        })
    }

    function handleChangeInfoCar(e){
        setInfoCarro({
            ...infoCarro,
            [e.target.name]: e.target.value
        })
    }

    function handleChangeImgCar(e){

        const {name, files} = e.target;
        if(files.length > 0){
            setImgCar({
                ...imgCar,
                [name]: files[0],
            })
        }
        
        
    }

    async function handleSubmitUpload(e){
        e.preventDefault()
        try{
            const formData = new FormData();
            for(const key in imgCar){
                if(imgCar[key]){
                    formData.append(key, imgCar[key]);
                }else{
                    throw new Error("Imagem Indefinida")
                }
            }

            for (const key in infoCarro) {
                if(infoCarro[key].length === 0){
                    console.log(infoCarro);
                    throw new Error("Campo indefinido")
                }
                else{
                    formData.append(key, infoCarro[key]);
                }
            }

            const add = await fetch(`${process.env.REACT_APP_UPLOAD}`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${auth.token}`
                },
                body: formData // envia o formData diretamente
            });
            
            if(add.ok){
               window.location.reload()
            }
            
        } catch(error){
            alert(error);
        }
    }

    function logout(){
        localStorage.clear();
        window.location.reload()
    }

    function openCloseCarroAdd(){
        if(editando){
            setEditando(false)
        }else{
            setEditando(true)
            if(deleteCar){
                setDeleteCar(false)
            }
        }
    }

    const [deleteCar, setDeleteCar] = useState(false)
    const [carroIdDel, setCarroIdDel] = useState(null);
    const [certezaDeletarCarro, setCertezaDeletarCar] = useState(false)

    function openCloseCarroDel() {
        if(deleteCar){
            setDeleteCar(false)
            
        } else{
            setDeleteCar(true)
            if(editando){
                setEditando(false)
            }
        }
    }

    async function deleteCarro(id){
        try{
            const deleteaaaa = await fetch(`${process.env.REACT_APP_DELETE_CAR}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${auth.token}`

                },
                body: JSON.stringify({id})
            })

            if(deleteaaaa.ok){
                window.location.reload()
            }

        }catch(error){
            alert(error);
        }
    }

    function deletandoCarroCaixa(id){
        setCertezaDeletarCar(true);
        setCarroIdDel(id);
    }

    function caixaPerguntaDelSim(){
        deleteCarro(carroIdDel)
        
    }
    

    function caixaPerguntaDelNao(){
        setCertezaDeletarCar(false);
    }


    const[carro_modelo_nao, setCarroModeloNao ] = useState(false);

    function filtrarCarros(carros, filtros) {
        const { marcas, ordenarPorPreco, ordenarPorComb } = filtros;
    
        // Filtrar pela marca se uma for selecionada
        let carrosFiltrados = marcas
            ? carros.filter(carro => carro.Marca?.toLowerCase() === marcas.toLowerCase())
            : carros;
    
        // Filtrar pelo combustível
        if (ordenarPorComb) {
            carrosFiltrados = carrosFiltrados.filter(carro => carro.Combustivel?.toLowerCase() === ordenarPorComb.toLowerCase());
        }
    
        // Ordenar pelo preço (menor para maior ou maior para menor)
        if (ordenarPorPreco === 'menor') {
            carrosFiltrados.sort((a, b) => Number(a.Valor) - Number(b.Valor));
        } else if (ordenarPorPreco === 'maior') {
            carrosFiltrados.sort((a, b) => Number(b.Valor) - Number(a.Valor));
        } else if (ordenarPorPreco === '') {
            window.location.reload();
        }
    
        if (carrosFiltrados?.length === 0) {
            return (
                <div className="carros-filtrados-zero">
                    <h1>EM BREVE</h1>
                </div>
            );
        } else {
            return (
                carrosFiltrados?.map((carro, index) => (
                    <div key={index} className="container-all-cars">
                        <div className={`grid-style${deleteCar ? ' deletando-carro' : ""}`}>
                            <div className="delete-carro"></div>
                            {!deleteCar && (
                                <div>
                                    <a className="link-carro-href" href={`/detalhe-carros?&id=${carro.ID}&marca=${carro.Marca}&motor=${carro.Motor}`}>
                                        <div className="imagem-content-ul">
                                            {/* Acessando as imagens diretamente do objeto carro */}
                                            {carro.Imagens && carro.Imagens?.map((imagem, index2) => (
                                                <img key={index2} src={imagem.imagem_principal} alt="Imagem Principal do Carro" />
                                            ))}
                                        </div>
    
                                        <div className="info-content-ul">
                                            <div className="info-content-center">
                                                <div className="info-carro-outros">
                                                    <div className="info-carro-marca-modelo">
                                                        <div className="info-carro-ano-cor"><span> {carro.Ano} - {carro.Cor}</span></div>
                                                        <div className="info-carro-marca-outros"><span> {carro.Marca} - {carro.Modelo}</span></div>
                                                        <div className="info-motor-combustivel-carroceria"><span> {carro.Motor}  {carro.Combustivel}  {carro.Carroceria}</span></div>
                                                        <div className="info-kilometragem"><span> {carro.Kilometragem} KM</span></div>
                                                        <div className="info-preco">{Number(carro.Valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                                                        <div className="info-localizacao"><span>Campinas-SP</span></div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )}
    
                            {deleteCar && (
                                <div className="deletando-carro" onClick={() => deletandoCarroCaixa(carro.ID)}>
                                    <div className="imagem-content-ul">
                                        {carro.Imagens && carro.Imagens?.map((imagem, index2) => (
                                            <img key={index2} src={imagem.imagem_principal} alt="Imagem Principal do Carro" />
                                        ))}
                                    </div>
    
                                    <div className="info-content-ul">
                                        <div className="info-content-center">
                                            <div className="info-carro-outros">
                                                <div className="info-carro-marca-modelo">
                                                    <ul>
                                                        <li className="ano-carro"><span>{carro.Ano} - {carro.Cor}</span></li>
                                                        <li><span className="carro-marca-modelo">{carro.Marca} {carro.Modelo}</span></li>
                                                        <li><span className="motor-combustivel-carroceria">{carro.Motor} {carro.Combustivel} {carro.Carroceria}</span></li>
                                                        <li><span className="kilometragem">{Number(carro.Kilometragem).toLocaleString('pt-BR')} km</span></li>
    
                                                        <div className="info-carro-preco">
                                                            <span className="preco">
                                                                {Number(carro.Valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} 
                                                                <span className="carro-cidade">campinas-SP</span>
                                                            </span>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))
            );
        }
    }

    function reload(){
        window.location.reload()
    }

    while(!carregandoCarros){
        return(
            <div className="carro-detalhes-carregando">
                <div className="carro-detalhes-carregando-centralized">
                    <span style={{'--i': 1}}>c</span>
                    <span style={{'--i': 2}}>a</span>
                    <span style={{'--i': 3}}>r</span>
                    <span style={{'--i': 4}}>r</span>
                    <span style={{'--i': 5}}>e</span>
                    <span style={{'--i': 6}}>g</span>
                    <span style={{'--i': 7}}>a</span>
                    <span style={{'--i': 8}}>n</span>
                    <span style={{'--i': 9}}>d</span>
                    <span style={{'--i': 10}}>o</span>
                    <span style={{'--i': 11}}>.</span>
                    <span style={{'--i': 12}}>.</span>
                    <span style={{'--i': 13}}>.</span>
                </div>

            </div>
        )
    }

    if(errorCarrosCarregados){
        return(
            <div className="carro-error-carro-detalhes-container">
                <h1>Ops! parece que ocorreu algum problema :(</h1>
                <span>reinicie a página, se o problema continuar, fique tranquilo, já estamos corrigindo.</span>
                <a className="reiniciar-pagina" onClick={reload}>reiniciar página</a>
            </div>
            
        )
    }else{

        if(isUserLoggedIn){
            return(
                <div className="estoque-principal-container">
                    <div className="estoque-all-container">
                    <div className="estoque-menu-top-consulta">
                        
                        <div className="estoque-menu-top-consulta-left">
                            <select name="marcas" onChange={handleChangeTipoCarro}>
                                <option value=''>Ordenar Marca</option>
                                <option value="Volkswagen">Volkswagen</option>
                                <option value="Fiat">Fiat</option>
                                <option value="Chevrolet">Chevrolet</option>
                                <option value="Renault">Renault</option>
                                <option value="Pegeot">Pegeot</option>
                                <option value="Toyota">Toyota</option>
                                <option value="Honda">Honda</option>
                                <option value="Citroen">Citroen</option>
                                <option value="Ford">Ford</option>
                                <option value="Hyundai">Hyundai</option>
                            </select>
                        </div>
    
                        <div className="estoque-menu-top-consulta-right">
                            <select name="ordenarPorPreco" onChange={handleChangeTipoCarro}>
                                <option value=''>Ordenar Preço</option>
                                <option value="menor">menor preço</option>
                                <option value="maior">maior preço</option>
                            </select>
                        </div>
    
    
                        <div className="estoque-menu-side-800px"></div>
                    </div>
    
                    <div className="menu-lateral-800px">
                        <div className="estoque-menu-side-800px">
                            <h4> REFINE SUA BUSCA
                            </h4>
                            <div className="mside-marcas-container-960px">
                                MARCAS
                            </div>
    
                            <div className="mside-marcas-content-960px">
                            
                                <ul>
                                        <li>
                                        <div className="mside-marcas-content-cars-960px"><input type="radio" value="" id="todas" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas===""}/>
                                        <label htmlFor="">Todas</label></div></li>                            
                                    
                                    <li>
                                        <div className="mside-marcas-content-cars-960px"><input type="radio" value="Volkswagen" id="Volkswagen" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Volkswagen"}/>
                                        <label htmlFor="Volkswagen">Volkswagen</label></div></li>                            
                                    <li><div className="mside-marcas-content-cars-960px" ><input type="radio" value="Fiat" id="Fiat" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Fiat"}/>
                                        <label htmlFor="Fiat">Fiat</label></div></li>
                                    <li><div className="mside-marcas-content-cars-960px"><input type="radio" value="Chevrolet" id="Chevrolet" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Chevrolet"}/>
                                        <label htmlFor="Chevrolet">Chevrolet</label></div></li>
                                    <li><div className="mside-marcas-content-cars-960px"><input type="radio" value="Renault" id="Renault" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Renault"}/>
                                        <label htmlFor="Renault">Renault</label></div></li>
                                    <li><div className="mside-marcas-content-cars-960px"><input type="radio" value="Pegeot" id="Pegeot" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Pegeot"}/>
                                        <label htmlFor="Pegeot">Pegeot</label></div></li>
                                    <li><div className="mside-marcas-content-cars-960px"><input type="radio" value="Toyota" id="Toyota" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Toyota"}/>
                                        <label htmlFor="Toyota">Toyota</label></div></li>
                                    <li><div className="mside-marcas-content-cars-960px"><input type="radio" value="Honda" id="Honda" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Honda"}/>
                                    <label htmlFor="Honda">Honda</label></div></li>
                                    <li><div className="mside-marcas-content-cars-960px"><input type="radio" value="Citroen" id="Citroen" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Citroen"}/>
                                    <label htmlFor="Citroen">Citroen</label></div></li>
                                    <li><div className="mside-marcas-content-cars-960px"><input type="radio" value="Ford" id="Ford" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Ford"}/>
                                    <label htmlFor="Ford">Ford</label></div></li>
                                    <li><div className="mside-marcas-content-cars-960px"><input type="radio" value="Hyundai" id="Hyundai" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Hyundai"}/>
                                    <label htmlFor="Hyundai">Hyundai</label></div></li>
                                   
    
    
                                </ul>
    
                               
    
    
                                
                            </div>
                            <div className="mside-preco-container-960px">
                                    PREÇO
                            </div>    
                            <div className="mside-preco-content-960px">
                                <ul>
                                    <li><div className="mside-marcas-content-cars-960px">
                                    <input type="radio" value="" id="todas" name="ordenarPorPreco" onChange={handleChangeTipoCarro} checked={filtros.ordenarPorPreco===""}/>
                                    <label>Todos</label></div></li>
                                    <li><div className="mside-marcas-content-cars-960px">
                                    <input type="radio" value="menor" id="menor" name="ordenarPorPreco" onChange={handleChangeTipoCarro} checked={filtros.ordenarPorPreco==="menor"}/>
                                    <label>Menor</label></div></li>
                                    <li><div className="mside-marcas-content-cars-960px">
                                    <input type="radio" value="maior" id="maior" name="ordenarPorPreco" onChange={handleChangeTipoCarro} checked={filtros.ordenarPorPreco==="maior"}/>
                                    <label>Maior</label></div></li>
                                </ul>
                            </div>
    
    
                            <div className="mside-preco-container-960px">
                                COMBUSTÍVEL
                            </div>
    
    
                            <div className="mside-preco-content-960px">
                                <ul>
                                    <li><div className="mside-marcas-content-cars-960px">
                                    <input type="radio" value="" id="todas" name="ordenarPorComb" onChange={handleChangeTipoCarro} checked={filtros.ordenarPorComb===""}/>
                                    <label>Todas</label></div></li>
                                    <li><div className="mside-marcas-content-cars-960px">
                                    <input type="radio" value="gasolina" id="gasolina" name="ordenarPorComb" onChange={handleChangeTipoCarro} checked={filtros.ordenarPorComb==="gasolina"}/>
                                    <label>Gasolina</label></div></li>
                                    <li><div className="mside-marcas-content-cars-960px">
                                    <input type="radio" value="alcool" id="alcool" name="ordenarPorComb" onChange={handleChangeTipoCarro} checked={filtros.ordenarPorComb==="alcool"}/>
                                    <label>Alcool</label></div></li>
                                    <li><div className="mside-marcas-content-cars-960px">
                                    <input type="radio" value="flex" id="flex" name="ordenarPorComb" onChange={handleChangeTipoCarro} checked={filtros.ordenarPorComb==="flex"}/>
                                    <label>Flex</label></div></li>
    
                                    <li><div className="mside-marcas-content-cars-960px">
                                    <input type="radio" value="eletrico" id="eletrico" name="ordenarPorComb" onChange={handleChangeTipoCarro} checked={filtros.ordenarPorComb==="eletrico"}/>
                                    <label>Elétrico</label></div></li>

                                    <li><div className="mside-marcas-content-cars-960px">
                                     <input type="radio" value="hibrido" id="hibrido" name="ordenarPorComb" onChange={handleChangeTipoCarro} checked={filtros.ordenarPorComb==="hibrido"}/>
                                     <label>Hibrido</label></div></li>
                                        
                                </ul>
                            </div>
                        </div>
    
                    </div>
                    
    
                    <div className="carros-disponiveis">
                        <h1>Carros disponiveis <span className="span-carro-length">({carros?.length})</span></h1>
                    </div>
    
                    
    
                    <div className="grid-container-car"> 
                        {filtrarCarros(carros, filtros)}
                    </div>
                    
                    
                    <div className="carros-all-content-800px">
                        <div className="carros-disponiveis-800px">
                            <h1 className="carros-disponiveis-h1-800px">Carros disponiveis <span className="span-carro-length">({carros?.length})</span> <span className="adicionar-carro-800px" onClick={openCloseCarroAdd}>adicionar carro +</span> <span className="delete-carro-800px" onClick={openCloseCarroDel}>   delete carro -</span><span className="delete-carro-800px" onClick={logout}>Logout</span></h1>
                        </div>
    
                        {editando && (
                            <div className="adicionando-carro-mobile">
                                <form onSubmit={handleSubmitUpload}>
                                <h1>Adicionando Veículo</h1>
                                <div className="adicionando-carro-800px-centralized"> 
                                    <div className="adicionando-carro-800px-left">
                                        <ul className="adicionando-carro-800px-ul">
                                                <li>
                                                    <label>MARCA</label>
                                                    <input type="text" name="marca" placeholder="marca" onChange={handleChangeInfoCar}></input>
                                                </li>
    
                                                <li>
                                                    <label>COMBUSTIVEL</label>
                                                    <input type="text" name="combustivel" placeholder="combustivel" onChange={handleChangeInfoCar}></input>
                                                </li>
    
                                                <li>
                                                    <label>KILOMETRAGEM</label>
                                                    <input type="text" name="kilometragem" placeholder="kilometragem" onChange={handleChangeInfoCar}></input>
                                                </li>
                                        </ul>
                                    </div>
                                    <div className="adicionando-carro-800px-center">
                                        <ul className="adicionando-carro-800px-ul">
                                                <li>
                                                    <label>MODELO</label>
                                                    <input type="text" name="modelo" placeholder="modelo" onChange={handleChangeInfoCar}></input>
                                                </li>
    
                                                <li>
                                                    <label>CARROCERIA</label>
                                                    <input type="text" name="carroceria" placeholder="carroceria" onChange={handleChangeInfoCar}></input>
                                                </li>
    
                                                <li>
                                                    <label className="adicionando-carro-preco">PREÇO</label>
                                                    <input type="text" name="preco" placeholder="preco" onChange={handleChangeInfoCar}></input>
                                                </li>
                                        </ul>
    
                                    </div>
                                    <div className="adicionando-carro-800px-right"> 
                                        <ul className="adicionando-carro-800px-ul">
                                                <li>
                                                    <label>MOTOR</label>
                                                    <input type="text" name="motor" placeholder="motor" onChange={handleChangeInfoCar}></input>
                                                </li>
    
    
                                                <li>
                                                    <label>ANO</label>
                                                    <input type="text" name="ano" placeholder="ano" onChange={handleChangeInfoCar}></input>
                                                </li>
                                        </ul>
    
                                    </div>
    
                                    
    
                                </div>
                                
                                
                                <div className="opcionais-carro-adicionando">
                                    <div className="cor">
                                    <ul className="adicionando-carro-800px-ul">
                                               
                                                <li>
                                                    <label>COR</label>
                                                    <input type="text" name="cor" placeholder="cor" onChange={handleChangeInfoCar}></input>
                                                </li>
                                        </ul>
                                    </div>
                                    <div className="opcionais">
                                        <label>Opcionais</label>
                                        <textarea type="text" name="opcionais" placeholder="ex: tudo sobre o carro: TIPO DE DIREÇÃO, " onChange={handleChangeInfoCar}/>
                                    </div>
                                    
                                </div>
    
    
                                <h1>Imagem Veículos</h1>
                                <div className="adicionando-carro-800px-centralized"> 
                                    <div className="adicionando-carro-800px-left">
                                        <ul className="adicionando-carro-800px-ul">
                                                <li>
                                                    <label>Imagem Principal</label>
                                                    <input name="imagemPrincipal" type="file" accept="image/*" onChange={handleChangeImgCar} />
    
                                                </li>
    
                                                <li>
                                                    <label>Imagem Lateral Roda</label>
                                                    <input name="imagem4" type="file" accept="image/*" onChange={handleChangeImgCar} />
                                                </li>
                                                
    
    
                                        </ul>
                                    </div>
                                    <div className="adicionando-carro-800px-center">
                                        <ul className="adicionando-carro-800px-ul">
                                                <li>
                                                    <label>Imagem Traseira</label>
                                                    <input name="imagem2" type="file" accept="image/*" onChange={handleChangeImgCar} />
                                                </li>
    
                                                <li>
                                                    <label>Imagem Interior</label>
                                                    <input name="imagem5" type="file" accept="image/*" onChange={handleChangeImgCar} />
                                                </li>
    
                                                
    
                                                <button className="adicionando-carro-botao-registrar">Adicionar Carro</button>
    
                                        </ul>
    
                                    </div>
                                    <div className="adicionando-carro-800px-right"> 
                                        <ul className="adicionando-carro-800px-ul">
                                                <li>
                                                    <label>Imagem Lateral Direita</label>
                                                    <input name="imagem3" type="file" accept="image/*" onChange={handleChangeImgCar} />
                                                </li>
    
                                                <li>
                                                    
                                                    <label>Imagem Banco</label>
                                                    <input name="imagem6" type="file" accept="image/*" onChange={handleChangeImgCar} />
    
                                                </li>
    
                                               
                                            
                                        </ul>
                            
                                    </div>
    
                                    
    
                                </div>
    
                                </form>
                            </div>
                        )}
    
                        {certezaDeletarCarro && (
                            <div className="certeza-del-carro">
                                <div className="caixa-pergunta-del-carro">
                                    <h1>Certeza que quer deletar o carro?</h1>
                                    <div className="separacao-caixa-sim-nao">
                                        <div className="caixa-delentando-sim"> <span onClick={caixaPerguntaDelSim}>SIM!</span></div>
                                        <div className="caixa-delentando-nao"> <span onClick={caixaPerguntaDelNao}>NÃO!</span> </div>
    
                                    </div>
                                </div>
                            </div>
                        )}
    
    
                        {certezaDeletarCarro && (
                            <div className="certeza-del-carro-1000px">
                                <div className="caixa-pergunta-del-carro">
                                    <h1>Certeza que quer deletar o carro?</h1>
                                    <div className="separacao-caixa-sim-nao">
                                        <div className="caixa-delentando-sim"> <span onClick={caixaPerguntaDelSim}>SIM!</span></div>
                                        <div className="caixa-delentando-nao"> <span onClick={caixaPerguntaDelNao}>NÃO!</span> </div>
    
                                    </div>
                                </div>
                            </div>
                        )}
    
    
                        <div className="grid-container-car-800px">
    
                            {filtrarCarros(carros, filtros)}
                            
                        </div>
                    </div>
    
                    
                    <div className="adicionar-carro-800px"></div>
                    
               </div>
                </div>
               
            )
        }else{
            return(
                <div className="estoque-principal-container">
                     <div className="estoque-all-container">
                     <div className="estoque-menu-top-consulta">
                         
                         <div className="estoque-menu-top-consulta-left">
                             <select name="marcas" onChange={handleChangeTipoCarro}>
                                 <option value=''>Ordenar Marca</option>
                                 <option value="Volkswagen">Volkswagen</option>
                                 <option value="Fiat">Fiat</option>
                                 <option value="Chevrolet">Chevrolet</option>
                                 <option value="Renault">Renault</option>
                                 <option value="Pegeot">Pegeot</option>
                                 <option value="Toyota">Toyota</option>
                                 <option value="Honda">Honda</option>
                                 <option value="Citroen">Citroen</option>
                                 <option value="Ford">Ford</option>
                                 <option value="Hyundai">Hyundai</option>
                             </select>
                         </div>
     
                         <div className="estoque-menu-top-consulta-right">
                             <select name="ordenarPorPreco" onChange={handleChangeTipoCarro}>
                                 <option value=''>Ordenar Preço</option>
                                 <option value="menor">menor preço</option>
                                 <option value="maior">maior preço</option>
                             </select>
                         </div>
     
     
                         <div className="estoque-menu-side-800px"></div>
                     </div>
     
                     <div className="menu-lateral-800px">
                         <div className="estoque-menu-side-800px">
                             <h4> REFINE SUA BUSCA
                             </h4>
                             <div className="mside-marcas-container-960px">
                                 MARCAS
                             </div>
     
                             <div className="mside-marcas-content-960px">
                             
                                 <ul>
                                         <li>
                                         <div className="mside-marcas-content-cars-960px"><input type="radio" value="" id="todas" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas===""}/>
                                         <label htmlFor="">Todas</label></div></li>                            
                                     
                                     <li>
                                         <div className="mside-marcas-content-cars-960px"><input type="radio" value="Volkswagen" id="Volkswagen" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Volkswagen"}/>
                                         <label htmlFor="Volkswagen">Volkswagen</label></div></li>                            
                                     <li><div className="mside-marcas-content-cars-960px" ><input type="radio" value="Fiat" id="Fiat" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Fiat"}/>
                                         <label htmlFor="Fiat">Fiat</label></div></li>
                                     <li><div className="mside-marcas-content-cars-960px"><input type="radio" value="Chevrolet" id="Chevrolet" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Chevrolet"}/>
                                         <label htmlFor="Chevrolet">Chevrolet</label></div></li>
                                     <li><div className="mside-marcas-content-cars-960px"><input type="radio" value="Renault" id="Renault" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Renault"}/>
                                         <label htmlFor="Renault">Renault</label></div></li>
                                     <li><div className="mside-marcas-content-cars-960px"><input type="radio" value="Pegeot" id="Pegeot" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Pegeot"}/>
                                         <label htmlFor="Pegeot">Pegeot</label></div></li>
                                     <li><div className="mside-marcas-content-cars-960px"><input type="radio" value="Toyota" id="Toyota" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Toyota"}/>
                                         <label htmlFor="Toyota">Toyota</label></div></li>
                                     <li><div className="mside-marcas-content-cars-960px"><input type="radio" value="Honda" id="Honda" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Honda"}/>
                                     <label htmlFor="Honda">Honda</label></div></li>
                                     <li><div className="mside-marcas-content-cars-960px"><input type="radio" value="Citroen" id="Citroen" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Citroen"}/>
                                     <label htmlFor="Citroen">Citroen</label></div></li>
                                     <li><div className="mside-marcas-content-cars-960px"><input type="radio" value="Ford" id="Ford" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Ford"}/>
                                     <label htmlFor="Ford">Ford</label></div></li>
                                     <li><div className="mside-marcas-content-cars-960px"><input type="radio" value="Hyundai" id="Hyundai" name="marcas" onChange={handleChangeTipoCarro} checked={filtros.marcas==="Hyundai"}/>
                                     <label htmlFor="Hyundai">Hyundai</label></div></li>
                                    
     
     
                                 </ul>
     
                                
     
     
                                 
                             </div>
                             
                             <div className="mside-preco-container-960px">
                                     PREÇO
                             </div>
     
                             <div className="mside-preco-content-960px">
                                 <ul>
                                     <li><div className="mside-marcas-content-cars-960px">
                                     <input type="radio" value="" id="todas" name="ordenarPorPreco" onChange={handleChangeTipoCarro} checked={filtros.ordenarPorPreco===""}/>
                                     <label>Todos</label></div></li>
                                     <li><div className="mside-marcas-content-cars-960px">
                                     <input type="radio" value="menor" id="menor" name="ordenarPorPreco" onChange={handleChangeTipoCarro} checked={filtros.ordenarPorPreco==="menor"}/>
                                     <label>Menor</label></div></li>
                                     <li><div className="mside-marcas-content-cars-960px">
                                     <input type="radio" value="maior" id="maior" name="ordenarPorPreco" onChange={handleChangeTipoCarro} checked={filtros.ordenarPorPreco==="maior"}/>
                                     <label>Maior</label></div></li>
                                 </ul>
                             </div>
     
     
                             <div className="mside-preco-container-960px">
                                 COMBUSTÍVEL
                             </div>
     
     
                             <div className="mside-preco-content-960px">
                                 <ul>
                                     <li><div className="mside-marcas-content-cars-960px">
                                     <input type="radio" value="" id="todas" name="ordenarPorComb" onChange={handleChangeTipoCarro} checked={filtros.ordenarPorComb===""}/>
                                     <label>Todas</label></div></li>
                                     <li><div className="mside-marcas-content-cars-960px">
                                     <input type="radio" value="gasolina" id="gasolina" name="ordenarPorComb" onChange={handleChangeTipoCarro} checked={filtros.ordenarPorComb==="gasolina"}/>
                                     <label>Gasolina</label></div></li>
                                     <li><div className="mside-marcas-content-cars-960px">
                                     <input type="radio" value="alcool" id="alcool" name="ordenarPorComb" onChange={handleChangeTipoCarro} checked={filtros.ordenarPorComb==="alcool"}/>
                                     <label>Alcool</label></div></li>
                                     <li><div className="mside-marcas-content-cars-960px">
                                     <input type="radio" value="flex" id="flex" name="ordenarPorComb" onChange={handleChangeTipoCarro} checked={filtros.ordenarPorComb==="flex"}/>
                                     <label>Flex</label></div></li>
     
                                     <li><div className="mside-marcas-content-cars-960px">
                                     <input type="radio" value="eletrico" id="eletrico" name="ordenarPorComb" onChange={handleChangeTipoCarro} checked={filtros.ordenarPorComb==="eletrico"}/>
                                     <label>Elétrico</label></div></li>

                                     <li><div className="mside-marcas-content-cars-960px">
                                     <input type="radio" value="hibrido" id="hibrido" name="ordenarPorComb" onChange={handleChangeTipoCarro} checked={filtros.ordenarPorComb==="hibrido"}/>
                                     <label>Hibrido</label></div></li>
                                         
                                 </ul>
                             </div>
                         </div>
     
                     </div>
                     
     
                     <div className="carros-disponiveis">
                         <h1>Carros disponiveis <span className="span-carro-length">({carros?.length})</span></h1>
                     </div>
     
     
     
                     <div className="grid-container-car"> 
                         {filtrarCarros(carros, filtros)}
                     </div>
                     
                     
                     <div className="carros-all-content-800px">
                         <div className="carros-disponiveis-800px">
                             <h1 className="carros-disponiveis-h1-800px">Carros disponiveis <span className="span-carro-length">({carros?.length})</span></h1>
                         </div>
     
                         <div className="grid-container-car-800px">
     
                             {filtrarCarros(carros, filtros)}
                             
                         </div>
                     </div>
     
                     
                </div>
                </div>

               
             )
        }

    }

    
    
}

export default Estoque;



