import React, { useContext, useState } from "react";
import {useNavigate} from 'react-router-dom';
import './style.css'
import { AuthContext } from "../context/auth";
function LoginAdmin(){
    const [user, setUser] = useState();
    const navigate = useNavigate()
    const { authentication } = useContext(AuthContext);
    const [error, setError] = useState(false);

    
    function handleChangeLogin(e){
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }

    async function handleSubmitLogin(e){
        e.preventDefault();
        try{
            const login = await authentication(user)
            if(login){
                navigate('/')
            }else{
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 2000);
            }
        }catch(error){
        }
    }

    return(
        <div className="sec-all-container-login">
            <div className="sec-principal-container-login">
                <form onSubmit={handleSubmitLogin}>
                    <div className="sec-title-login">
                        <h1>admin page login</h1>
                    </div>

                    <div className="sec-fields-login">
                        <label>Nome</label>
                        <input type="text" name="nome" placeholder="Nome" onChange={handleChangeLogin}/>
                    </div>

                    <div className="sec-fields-login">
                        <label>Senha</label>
                        <input type="password" name="password" placeholder="Senha" onChange={handleChangeLogin}/>
                    </div>
                    <hr/>
                    <div className="sec-fields-login">
                        <button>Logar-se</button>
                    </div>
                    {error && (<div className="sec-fields-login login-error">Login error!</div>)}
                </form>
            </div>
        </div>
    )
}

export default LoginAdmin;