import React,{useEffect} from "react";
import {Routes, Route, useLocation} from 'react-router-dom';
import Emprestimo from "../Emprestimo";
import RegisterAdmin from "../RegisterAdmin";
import LoginAdmin from "../LoginAdmin";
import Estoque from "../Estoque";
import Empresa from "../Empresa";
import DetalheCarros from "../Carros";
import Contato from "../Contato";

export default function Routez(){
    const location = useLocation();
    
    
        
    return(
        <Routes>
            <Route path="/emprestimo" element={<Emprestimo/>} />
            <Route path="/medidaLogin/area/restrita/admin/login" element={<LoginAdmin/>}/>
            <Route path="/estoque" element={<Estoque/>} />
            <Route path="/detalhe-carros" element={<DetalheCarros/>} /> 
            <Route path="/contato" element={<Contato />} />
            <Route path="/" element={<Empresa/>} />
        </Routes>
    )
}

/**
/***/ // /estoqueTeste