import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Header from "./Componentes/Header";
import Routez from "./Componentes/routes/routes";
import { AuthProvider } from "./Componentes/context/auth";
import Footer from "./Componentes/footer";
import NonPage from "./Componentes/NonPage";
import HeaderHome from "./Componentes/HeaderHome";


function App() {

  
  
  

  return (
    <>
    <AuthProvider>
      <BrowserRouter>
        <HandlePageDeleteHeader/>
        <HandleHeaderHome/>
        <ReturnHome/>
        <Routez />
        <HandlePageDeleteFooter />
      </BrowserRouter>
      </AuthProvider>
    </>
  );

 
}

function HandlePageDeleteHeader(){
  const location = useLocation()
  const navigate = useNavigate();
  const paginas = ['/medidaSeguranca/area/restrita/admin/register', '/medidaLogin/area/restrita/admin/login', "/"]
  if(paginas.includes(location.pathname)){
    return paginas.includes(location.pathname) ? null : <Header/>;

  }
  else{
    return !paginas.includes(location.pathname) ? <Header/> : null;

  }
}

function HandleHeaderHome(){
  const location = useLocation()
  const navigate = useNavigate();
  const paginacao = ["/"]

  if(paginacao.includes(location.pathname)){
    return paginacao.includes(location.pathname) ? <HeaderHome/> : null;
  }
}


function HandlePageDeleteFooter(){
  const location = useLocation()
  const paginas = ['/medidaSeguranca/area/restrita/admin/register', '/medidaLogin/area/restrita/admin/login']

  const paginaExistentes = ['/', '/medidaLogin/area/restrita/admin/login', '/estoque', '/emprestimo', '/contato', '/detalhe-carros']
  if(paginas.includes(location.pathname)){
    return paginas.includes(location.pathname) ? null : <Footer/>;
  }else{
    return paginas.includes(location.pathname) ? null : <Footer/>;
  }
}

function ReturnHome() {
  const navigate = useNavigate();
  const location = useLocation();
  
  const paginasExistentes = ['/', '/emprestimo', '/estoque', '/detalhe-carros', '/contato', '/medidaLogin/area/restrita/admin/login'];
  
  useEffect(() => {
    if (!paginasExistentes.includes(location.pathname)) {
      navigate('/');
    }
  }, [location.pathname, navigate]);

  return null; // Retorna null porque esse componente só serve para redirecionamento
}


export default App;