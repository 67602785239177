import React from "react";
import './style.css';
import logoWhats from '../assets/logoWhats.png'
import logoInsta from '../assets/Instagram_icon.png'
import logoYout from '../assets/youtube-logo.png'
import imageLocalizacao from '../assets/imagem-locali.jpeg'

function Contato(){

    return (
        <div className="contato-principal-container">
            <div className="contato-all-container">
            <div className="contato-centralized-container">
                <h1>SIGA-NOS EM TODAS NOSSAS REDES SOCIAIS</h1>
                
                <span>Fique por dentro de todas as novidades!
                Você não vai querer perder o que estamos preparando para você. Siga-nos nas redes sociais e seja o primeiro a saber sobre nossos lançamentos exclusivos, ofertas imperdíveis e dicas incríveis que vão transformar seu dia a dia!</span>

                <div className="contato-contatos">
                    <ul>
                        <li><a href="https://web.whatsapp.com/send?phone=1932316655" target="blank"><img src={logoWhats}/><span>(19) 32316655</span></a> </li>
                        <li><a href="https://www.instagram.com/lagomulti/" target="blank"><img src={logoInsta}/><span>@Lagomulti</span></a>  </li>
                        <li><a href="https://www.youtube.com/@LagoMultimarcas" target="blank"><img src={logoYout}/> <span>LagoMultiMarcas</span> </a></li>

                    </ul>
                </div>

                <h1>HORÁRIO DE FUNCIONAMENTO E LOCALIZAÇÃO</h1>

                <span className="black-color">Segunda a Sexta: 8:30 AM – 6 PM</span>
                <span className="black-color">De Sabado: 8:30 AM – 1 PM</span>

                <a className="localizacao-image" target="blank" href="https://www.google.com/maps/place/Lago+Multimarcas/@-22.9325444,-47.0842597,17z/data=!3m2!4b1!5s0x94c8c8e16963cb8b:0x80bac6443a781cef!4m6!3m5!1s0x94c8c8e169bca735:0xdbe407be9b9aa3ee!8m2!3d-22.9325494!4d-47.0816848!16s%2Fg%2F1q62pxwj9?entry=ttu&g_ep=EgoyMDI0MDkxNS4wIKXMDSoASAFQAw%3D%3D"><img src={imageLocalizacao}/></a>

            </div>

            <div className="contato-centralized-container-1000px">
                <div className="contato-left-container-1000px">
                    <div className="contato-left-centralized">
                        <h1>SIGA-NOS EM TODAS NOSSAS REDES SOCIAIS</h1>
                        
                        <div className="span-siganos"> <span className="span-">Fique por dentro de todas as novidades!
                         Siga-nos nas redes sociais e seja o primeiro a saber sobre nossos lançamentos exclusivos e ofertas imperdíveis!</span>
                        </div>
                       
                        <div className="contato-contatos">
                            <ul>
                                <li><a href="https://web.whatsapp.com/send?phone=1932316655" target="blank"><img src={logoWhats}/><span>(19) 32316655</span></a> </li>
                                <li><a href="https://www.instagram.com/lagomulti/" target="blank"><img src={logoInsta}/><span>@Lagomulti</span></a>  </li>
                                <li><a href="https://www.youtube.com/@LagoMultimarcas" target="blank"><img src={logoYout}/> <span>LagoMultiMarcas</span> </a></li>

                            </ul>
                        </div>
                    </div>
                    

                </div>

                <div className="contato-right-container-1000px">
                    <h1>HORÁRIO DE FUNCIONAMENTO E LOCALIZAÇÃO</h1>

                    <span>Segunda a Sexta: 8:30h – 18:00h</span>
                    <span>Aos Sábados: 8:30h – 13:00h</span>

                    <a className="localizacao-image" target="blank" href="https://www.google.com/maps/place/Lago+Multimarcas/@-22.9325444,-47.0842597,17z/data=!3m2!4b1!5s0x94c8c8e16963cb8b:0x80bac6443a781cef!4m6!3m5!1s0x94c8c8e169bca735:0xdbe407be9b9aa3ee!8m2!3d-22.9325494!4d-47.0816848!16s%2Fg%2F1q62pxwj9?entry=ttu&g_ep=EgoyMDI0MDkxNS4wIKXMDSoASAFQAw%3D%3D"><img src={imageLocalizacao}/></a>

                </div>

                
               
            </div>


        </div>
        </div>
        

        

        
    )
}

export default Contato;

//8:30 AM–6 PM
//8:30 AM–1 PM