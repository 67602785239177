import React, { createContext, useCallback, useState, useEffect } from "react";
import api from '../services/api'

const AuthContext = createContext();

const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState({});
    useEffect(() => {
        // Recupera os dados do localStorage no carregamento do componente
        const tokenData = localStorage.getItem('@token:multiLago');
        if (tokenData) {
            // Parse do JSON para objeto JavaScript
            const tokenObject = JSON.parse(tokenData);
            // Define os dados de autenticação no estado
            setAuth(tokenObject);
            
        }
    }, []);


    const authentication = useCallback(async ({ nome, password })=>{
        try {
                    const user = {nome: nome, senha: password};
                    const response = await fetch(`${process.env.REACT_APP_ROTA}`, {
                        method: "POST",
                        headers: {"Content-Type": "application/json"},
                        body: JSON.stringify(user)
                    })
                    if(!response.ok){
                        return false;
                        throw new Error()
                    }

                    const data = await response.json();
                    setAuth(data);
                    localStorage.setItem('@token:multiLago', JSON.stringify(data));
                    return true;
                    
        } catch(error) {
            return { success: false, error: 1 };
            
        }
    }, []);

    return(
        <AuthContext.Provider value={{authentication, auth}}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider };


/**const authentication = useCallback(async ({ nome, password })=>{
        try {
                    console.log(nome, password)
                    const response = await api.post(`/${process.env.REACT_APP_ROTA}`, {nome, password});
                    const {data} = response;
                    setAuth(data);
                    localStorage.setItem('@token:multiLago', JSON.stringify(data));
        } catch(error) {
            return { success: false, error: 1 };
            
        }
    }, []);

    return(
        <AuthContext.Provider value={{authentication, auth}}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider }; */