import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import api from "../services/api";
import { register } from 'swiper/element/bundle'
import { Swiper, SwiperSlide } from "swiper/react";
import './style.css';
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import setinha from '../assets/setinha.png'

<script src="https://cdnjs.cloudflare.com/ajax/libs/dompurify/2.0.15/purify.min.js"></script>




function DetalheCarros(){
    const location = useLocation();
    register()
    const searchParams = new URLSearchParams(location.search);
    
    const id = searchParams.get("id");
    
    const [carro, setCarro] = useState({})
    const [imagem, setImagem] = useState({})
    const [imagemPrincipal, setImagemPrincipal] = useState();
    const [errorCarro, setErrorCarro] = useState(false);
    const [carroEncontrado, setCarroEncontrado] = useState(false);

    const imagensArray = [
        { id: '1', image: imagem.imagem_principal},
        { id: '2', image: imagem.imagem2},
        { id: '3', image: imagem.imagem3},
        { id: '4', image: imagem.imagem4},
        { id: '5', image: imagem.imagem5},
        { id: '6', image: imagem.imagem6},
    ];

    
    useEffect(() => {
        const fetchCarro = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_DETALHE}?id=${id}`, {
                    method: "GET",
                    headers: { "Content-Type": "application/json" }
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
    
                const data = await response.json();
                setCarroEncontrado(true);
                setCarro(data.carro);
                setImagem(data.imagem[0]);
                setImagemPrincipal(data.imagem[0].imagem_principal);
            } catch (err) {
                setCarroEncontrado(true);
                setErrorCarro(true);
            }
        };
    
        fetchCarro();
    }, []);
    
    let opcionais = []
    var index = 0;

    if(carro.Opcionais){
        const opcionais1 = carro.Opcionais.split(',')
        for(let opcao of opcionais1){
            opcionais.push({id: index,opcao: opcao.trim()});
            index++;
        }
    }

    let opcionaisMetade = []
    let opcionaisMetade2 = []
    let index2 = 0;

    if(carro.Opcionais){
        const opcionais1 = carro.Opcionais.split(',');
        for(let opcao of opcionais1){
            if(opcionaisMetade.length <= (opcionais1.length / 2)){
                opcionaisMetade.push({id: index2, opcao: opcao.trim()})
                index2++;
            }else{
                opcionaisMetade2.push({id: index2, opcao: opcao.trim()})
                index2++;
                
            }
        }
    }


    while(!carroEncontrado){
        return(
            <div className="carro-detalhes-carregando">
                <div className="carro-detalhes-carregando-centralized">
                    <span style={{'--i': 1}}>c</span>
                    <span style={{'--i': 2}}>a</span>
                    <span style={{'--i': 3}}>r</span>
                    <span style={{'--i': 4}}>r</span>
                    <span style={{'--i': 5}}>e</span>
                    <span style={{'--i': 6}}>g</span>
                    <span style={{'--i': 7}}>a</span>
                    <span style={{'--i': 8}}>n</span>
                    <span style={{'--i': 9}}>d</span>
                    <span style={{'--i': 10}}>o</span>
                    <span style={{'--i': 11}}>.</span>
                    <span style={{'--i': 12}}>.</span>
                    <span style={{'--i': 13}}>.</span>
                </div>

            </div>
        )
    }

    if(errorCarro){
        return(
            <div className="carro-error-carro-detalhes-container">
                <h1>Parece que não existe esse carro :(</h1>
                <span>Retorne ao menu de estoque e veja nossas opções</span>
                <a href="/estoque">retornar</a>
            </div>

        )
    }else{
        return(
            <div className="carro-principal-container">
                <div className="carro-all-container">
                <div className="carro-voltar-estoque">
                    <a href="/estoque"><img src={setinha} alt="estoque"/></a>
                </div>
                <div className={`carro-imagem-principal image-container`}>
                    <Swiper
                        slidesPerView={1}
                        pagination={{clickable: true}}
                        navigation
                        
                        style={{
                            "--swiper-pagination-color": "#fff",
                            "--swiper-navigation-color": "#fff",
                          }}
                    >
                        {imagensArray.map((item) => (
                            <SwiperSlide key={item.id}>
                                <img src={item.image} alt="carros" className="image-tamanho"/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
        
                </div>
        
                <div className="carro-informacoes">
                    <div className="carro-informacoes-container-right">
                        <span className="linha-marca-modelo-1000px">{carro.Marca} {carro.Modelo}</span>
                        <span className="linha-motor-comb-carroce-1000px">{carro.Motor} {carro.Combustivel} {carro.Carroceria}  {carro.Cor}</span>
                        <span className="ano-fab-1000px">Modelo: {carro.Ano}</span>
                    </div>
                    <div className="carro-informacoes-container-left">
                        <span className="valor-1000px">{Number(carro.Valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>

                    </div>

                </div>

                <div className="box-informacoes">
                    <div className="box-informacoes-left"><span>{carro.Combustivel}</span></div>
                    <div className="box-informacoes-center">{carro.Carroceria}</div>
                    <div className="box-informacoes-right">{carro.Kilometragem} KM</div>

                </div>
        
                <div className="carro-opcionais">
                        <div className="carro-opcionais-titulo">
                            <span>OPCIONAIS: </span>
                        </div>
                        <div className="carro-opcionais-conteudo">
                            <ul>
                                {opcionais.map((item) => (
                                    <li key={item.id}>
                                        <span>{item.opcao}</span>
                                    </li>
                                 ))}
                            </ul>
                        
                            
                        </div>
        
                </div>
        
        
                <div className="carro-observações">
                        <div className="pergunta-gostou">
                            <span>GOSTOU DO CARRO?</span>
                        </div>
        
                        <div className="observacao">
                            <p>Para mais informações sobre o veículo, entre em contato com o numero: <strong>(19) 3231-6655</strong>, pelo email: <strong>contato@lagomultimarcas.com.br</strong>
                                , ou simplemente <strong><a href={`/emprestimo?&carro=${carro.Marca}&modelo=${carro.Modelo}&cor=${carro.Cor}`}>clique aqui</a></strong>
                            </p>
                        </div>
                </div>
        
                <div className="carro-all-container-100px">
                    <div className="carro-container-top-1000px">
                    <div className="carro-container-left-1000px">
                            <div className="voltar-estoque-100px">
                                <a href="/estoque"><img src={setinha} alt="estoque"/></a>
        
                            </div>
                            
                            <div className="carro-imagem-container-1000px">
                                    <Swiper
                                    slidesPerView={1}
                                    pagination={{clickable: true}}
                                    navigation
                                    style={{
                                        "--swiper-pagination-color": "#fff",
                                        "--swiper-navigation-color": "#fff",
                                    }}
                                >
                                    {imagensArray.map((item) => (
                                        <SwiperSlide key={item.id}>
                                            <img src={item.image} alt="carros" className="image-carro-1000px"/>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
        
                            <div className="pergunta-gostou-1000px">
                                <span>GOSTOU DO CARRO?</span>
                            </div>
        
                            <div className="carro-observacao-1000px">
                                <p>Para mais informações sobre o veículo, entre em contato com o numero: <strong>(19) 3231-6655</strong>, pelo email: <strong>contato@lagomultimarcas.com.br</strong>
                                    , ou simplemente <strong><a href={`/emprestimo?&carro=${carro.Marca}&modelo=${carro.Modelo}&cor=${carro.cor}`}>clique aqui</a></strong>
                                </p>
                            </div>
        
                        </div>    
        
                        <div className="carro-container-right-1000px">
                            <div className="carro-informacoes-right-1000px">
                                        <span className="linha-marca-modelo-1000px">{carro.Marca} {carro.Modelo}</span>
                                        <span className="linha-motor-comb-carroce-1000px">{carro.Motor} {carro.Combustivel} {carro.Carroceria}  {carro.Cor}</span>
                                        <span className="ano-fab-1000px">Modelo: {carro.Ano}</span>
                                        <span className="valor-1000px">{Number(carro.Valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
        
                            </div>
        
                            <div className="carro-informacoes-caixa-1000px">
                                <span>{carro.Combustivel}</span><span>{carro.Carroceria}</span><span>{Number(carro.Kilometragem).toLocaleString('pt-BR')} KM</span>
        
                            </div>
        
                            <div className="carro-opicionais-titulo-1000px">
                                <span>OPCIONAIS:</span>
                            </div>
        
                            <div className="carro-opcionais-conteudo-1000px">
                                    <div className="carro-opcionais-left-1000px">
                                        <ul>
                                            {opcionaisMetade.map((item) => (
                                                <li key={item.id}>{item.opcao}</li>
                                            ))}
                                        </ul>
                                    </div>
        
                                    <div className="carro-opcionais-right-1000px">
                                        <ul>
                                            {opcionaisMetade2.map((item) => (
                                                <li key={item.id}>{item.opcao}</li>
                                            ))}
                                        </ul>
                                    </div>
        
                            </div>
                        </div> 
        
                    </div>
        
                </div>
                   
               
               
            </div>
            </div>
            )

    }
    
   
}

export default DetalheCarros;