import React from "react";
import './style.css';
import localizacao from '../assets/localizacaoIcon.jpeg'
import iconInsta from '../assets/instagramIcon.jpeg'
import iconWhats from '../assets/whatsappIcon.jpeg'
import iconMail from '../assets/emailIcon2.jpeg'
import youtubeIcon from '../assets/youtubeIcon.jpeg'
 
function Footer(){
    return(
        <div className="footer-principal-container">
            <footer className="footer-all-container">
            <div className="footer-container">
                <div className="footer-loja-titulo-principal">
                    <span>Nossa Loja:</span>
                </div>

                <div className="footer-informacoes-loja">
                    <ul>
                        <li><a href='https://www.google.com/maps/place/Lago+Multimarcas/@-22.9325444,-47.0842597,17z/data=!3m2!4b1!5s0x94c8c8e16963cb8b:0x80bac6443a781cef!4m6!3m5!1s0x94c8c8e169bca735:0xdbe407be9b9aa3ee!8m2!3d-22.9325494!4d-47.0816848!16s%2Fg%2F1q62pxwj9?entry=ttu&g_ep=EgoyMDI0MDkxNS4wIKXMDSoASAFQAw%3D%3D' className="left-margin"><img src={localizacao}/> <span>Avenida Adão Focesi, 315, Jardim do Lago - Campinas SP</span></a></li>
                        <li><a href="https://web.whatsapp.com/send?phone=1932316655" target="blank"><img src={iconWhats} /> (19) 3231-6655</a></li>
                        <li><a><img src={iconMail} className="iconPngEmail"/>contato@lagomultimarcas.com.br</a></li>

                    </ul>
                </div>

                <div className="footer-loja-titulo-principal">
                    <span>Redes Sociais:</span>
                </div>

                <div className="footer-informacoes-loja">
                    <ul>
                        <li><a href="https://www.instagram.com/lagomulti/" target="blank"><img src={iconInsta}/> Instagram</a></li>
                        <li><a href="https://www.youtube.com/@LagoMultimarcas" target="blank"><img src={youtubeIcon} className="logoYoutube"/>Youtube</a></li>
                        <li><a></a></li>

                    </ul>
                </div>

                <div className="footer-informacoes-container-800px">
                    <div className="footer-informacoes-left-800px">
                        <div className="footer-loja-titulo-principal-800px">
                            <span>Nossa Loja:</span>
                        </div>

                        <div className="footer-informacoes-loja-800px">
                            <ul>
                                <li><a href='https://www.google.com/maps/place/Lago+Multimarcas/@-22.9325444,-47.0842597,17z/data=!3m2!4b1!5s0x94c8c8e16963cb8b:0x80bac6443a781cef!4m6!3m5!1s0x94c8c8e169bca735:0xdbe407be9b9aa3ee!8m2!3d-22.9325494!4d-47.0816848!16s%2Fg%2F1q62pxwj9?entry=ttu&g_ep=EgoyMDI0MDkxNS4wIKXMDSoASAFQAw%3D%3D' target="blank" className="left-margin"><img src={localizacao}/> <span>Avenida Adão Focesi, 315, Jardim do Lago - Campinas SP</span></a></li>
                                <li><a href="https://web.whatsapp.com/send?phone=1932316655" target="blank"><img src={iconWhats} /> (19) 3231-6655</a></li>
                                <li><a><img src={iconMail} className="iconPngEmail"/>contato@lagomultimarcas.com.br</a></li>

                            </ul>
                        </div>
                    </div>
                    <div className="footer-informacoes-right-800px">
                        <div className="footer-loja-titulo-principal-800px">
                            <span>Redes Sociais:</span>
                        </div>

                        <div className="footer-informacoes-loja-800px">
                            <ul>
                                <li><a href="https://www.instagram.com/lagomulti/" target="blank"><img src={iconInsta}/> Instagram</a></li>
                                <li><a href="https://www.youtube.com/@LagoMultimarcas" target="blank"><img src={youtubeIcon} className="logoYoutube"/>Youtube</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="footer-informacoes-right-1200px">
                        <div className="footer-loja-titulo-principal-800px">
                            <span>Menu:</span>
                        </div>

                        <div className="footer-informacoes-loja-800px">
                            <ul>
                                <li><a href="/">EMPRESA</a></li>
                                <li><a href="/estoque">VEÍCULOS</a></li>
                                <li><a href="/emprestimo">FINANCIAMENTO</a></li>
                                <li><a href="/contato">CONTATO</a></li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className="footer-loja-titulo-principal">
                    <span>Menu:</span>
                </div>


                <div className="footer-informacoes-loja">
                    <ul>
                        <li><a href="/">EMPRESA</a></li>
                        <li><a href="/estoque">VEÍCULOS</a></li>
                        <li><a href="/emprestimo">FINANCIAMENTO</a></li>
                        <li><a href="/contato">CONTATO</a></li>
                    </ul>
                </div>


            </div>
        </footer>
        </div>
        
    )
}

export default Footer;

