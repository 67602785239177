import React from "react";
import { useState } from "react";
import './style.css'

// Importes destinado as imagens
import menu from '../assets/menu.png'
import logo from '../assets/logo.png'
import whatsapp from '../assets/whatsappIcon.jpeg'
import logoWhats from '../assets/logoWhats.png'
import logoInsta from '../assets/Instagram_icon.png'
import logoYout from '../assets/youtube-logo.png'

import imagemHistoria2 from '../assets/infoEmpresaNovoPc.jpeg'
import imagemFinanciamento2 from '../assets/imagemFinanciamento2.jpeg'
import imagemLocalizacao2 from '../assets/imagemLocalizacao2.jpeg'
import imagemContato2 from '../assets/imagemContato2.jpeg'

import contato2 from '../assets/emailIcon2.jpeg'

import { register } from 'swiper/element/bundle'
import { Swiper, SwiperSlide } from "swiper/react";
import './style.css';
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// fechamento de import de imagens

function HeaderHome(){
    register()

    const [menuOpen, setMenuOpen] = useState(false);

    function openAndClose(){
        if(menuOpen){
            setMenuOpen(false)
        }else{
            setMenuOpen(true);
        }
    }

    function close(){
        setMenuOpen(false)
    }

    const imagens_empresa2 = [
        {id: '1', href: '/estoque', image: imagemHistoria2},
        {id: '2', href: 'https://www.google.com/maps/place/Lago+Multimarcas/@-22.9325444,-47.0842597,17z/data=!3m2!4b1!5s0x94c8c8e16963cb8b:0x80bac6443a781cef!4m6!3m5!1s0x94c8c8e169bca735:0xdbe407be9b9aa3ee!8m2!3d-22.9325494!4d-47.0816848!16s%2Fg%2F1q62pxwj9?entry=ttu&g_ep=EgoyMDI0MDkxNS4wIKXMDSoASAFQAw%3D%3D',image: imagemLocalizacao2},
        {id: '3', href: '/emprestimo', image: imagemFinanciamento2},
        {id: '4', href: '/contato', image: imagemContato2},
        {id: '5', href: '/estoque', image: imagemHistoria2},
        {id: '6', href: 'https://www.google.com/maps/place/Lago+Multimarcas/@-22.9325444,-47.0842597,17z/data=!3m2!4b1!5s0x94c8c8e16963cb8b:0x80bac6443a781cef!4m6!3m5!1s0x94c8c8e169bca735:0xdbe407be9b9aa3ee!8m2!3d-22.9325494!4d-47.0816848!16s%2Fg%2F1q62pxwj9?entry=ttu&g_ep=EgoyMDI0MDkxNS4wIKXMDSoASAFQAw%3D%3D',image: imagemLocalizacao2},
        {id: '7', href: '/emprestimo', image: imagemFinanciamento2},
        {id: '8', href: '/contato', image: imagemContato2}

    ]

    return(

        <div className="homeheader-principal-container">
            <div className="homeheader-all-container">
            



            
            <div className="homeheader-container-principal">
                <div className="homeheader-left-container">
                    <a href="/"><img src={logo} alt="lago multimarcas"/></a>
                </div>

                <div className="homeheader-right-container">
                    <img onClick={openAndClose} src={menu} alt="menu"/>
                </div>
            </div>



            {menuOpen && (
                <div className={`header-menu-open-mobile ${menuOpen ? 'open' : ''}`}>
                <ul className="header-menu-open-mobile-ul">
                    <li><a href="/">EMPRESA</a></li>
                    <li><a href="/estoque">ESTOQUE</a></li>
                    <li><a href="/emprestimo">FINANCIAMENTO</a></li>
                    <li><a href="/contato">CONTATO</a></li>
                    <li className="icon-contato"><img src={contato2} alt="email"/><span className="contato-content">contato@lagomultimarcas.com.br</span></li>
                    <li className="icon-contato-whats"><img src={whatsapp} alt="whatsapp"/> <span><a href="https://web.whatsapp.com/send?phone=1932316655" target="blank" className="color-black margin-left-0">(19) 3231-6655</a></span></li>


                </ul>
            </div>
            )}




            {/**ALA PARA FAZER PARA TELAS GRANDES */}
            <div className="homeheader-container-top-830px">
                <div className="homeheader-container-top-left-830px">
                    <a href="/"><img src={logo} alt="logoMultiMarcas"/></a>
                </div>
                <div className="homeheader-container-top-right-830px">
                        <Swiper
                        slidesPerView={1}
                        slidesPerGroup={1} 
                        autoplay={{
                            delay: 5000,
                        }}
                        pagination={{clickable: true}}
                        navigation
                        speed={1000}
                        loop={true}
                        
                        style={{
                            "--swiper-pagination-color": "#000",
                            "--swiper-navigation-color": "#000",
                        }}
                    >
                        {imagens_empresa2.map((item) => (
                            <SwiperSlide key={item.id}>
                                <a href={item.href}><img src={item.image} alt="imagens"/></a>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

            </div>
            <div className="homeheader-container-bottom-830px">
                <div className="homeheader-container-bottom-left-830px">
                    <ul>
                        <li><a href="#"><img className="logowhatsapp" src={logoWhats} alt="whatsapp"/></a></li>
                        <li><a href="#"><img src={logoInsta} alt="whatsapp"/></a></li>
                        <li><a href="#"><img src={logoYout} alt="whatsapp"/></a></li>

                    </ul>
                </div>
                <div className="homeheader-container-bottom-right-830px">
                    <ul>
                        <li><a href="/">EMPRESA</a></li>
                        <li><a href="/estoque">ESTOQUE</a></li>
                        <li><a href="/emprestimo">FINANCIAMENTO</a></li>
                        <li><a href="/contato">CONTATO</a></li>

                    </ul>
                </div>

            </div>
            
        </div>

        </div>
        
        
    )
}

export default HeaderHome;

/**<div className="header-container-principal">
                <div className="header-left-container">
                    <a href="/"><img src={logo} alt="lago multimarcas"/></a>
                </div>
                <div className="header-right-container">
                    
                    <ul className="header-right-container-800px">
                        <ul>
                            <li><a href="/">EMPRESA</a></li>
                            <li><a href="/estoque">VEÍCULOS</a></li>
                            <li><a href="/emprestimo">FINANCIAMENTO</a></li>
                            <li><a href="/contato">CONTATO</a></li>
                        </ul>
                    </ul>
                    <img onClick={openAndClose} src={menu} alt="menu"/>
                </div>
                <div className="header-contato-800px">
                    <a href="https://web.whatsapp.com/send?phone=1932316655" target="blank"><img src={logoWhats}/></a>
                    <a href="https://www.instagram.com/lagomulti/" target="blank"><img src={logoInsta}/></a>
                    <a href="https://www.youtube.com/@LagoMultimarcas" target="blank"><img className="youtube-logo" src={logoYout}/></a>

                </div>
            </div>

            {menuOpen && (
                <div className={`header-menu-open-mobile ${menuOpen ? 'open' : ''}`}>
                    <ul className="header-menu-open-mobile-ul">
                        <li><a href="/">EMPRESA</a></li>
                        <li><a href="/estoque">ESTOQUE</a></li>
                        <li><a href="/emprestimo">FINANCIAMENTO</a></li>
                        <li><a href="/contato">CONTATO</a></li>
                        <li className="header-info-contato">
                            <ul className="header-info-contato-ul">
                                <li className="whatsapp-content"><img src={whatsapp} alt="whatsapp"/> <span><a href="https://web.whatsapp.com/send?phone=1932316655" target="blank">(19) 3231-6655</a></span></li>
                                <li><img src={contato2}/><span>contato@lagomultimarcas.com.br</span></li>
                            </ul>
                        </li>

                    </ul>
                </div>
            )} */