import React, {useEffect, useState, useRef} from "react";
import faixada from '../assets/faixada-editada.jpg'
import api from "../services/api";
import localizacao from '../assets/localizacao.png'
import './style.css';
import { register } from 'swiper/element/bundle'
import { Swiper, SwiperSlide } from "swiper/react";
import './style.css';
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import historiaImg from '../assets/infoEmpresaNovoCelular.jpeg'
import enderecoImg from '../assets/EnderecoImg.jpeg'
import emprestimoImg from '../assets/EmprestimoImg.jpeg'
import contatoImagem from '../assets/infoContatoNovoCelular.jpeg'

function Empresa(){
    register()
    const [carros, setCarros] = useState([]);
    const [imagens, setImagens] = useState([]);
    const [carrosFiltrados, setCarrosFiltrados] = useState([]);
    const [imagensFiltradas, setImagensFiltradas] = useState([]);



     useEffect(() => {


         const fetchCarro = async () => {
             try{
                 await fetch(`${process.env.REACT_APP_FILTRO}`, {
                     method: 'GET',
                     headers: { "Content-Type": "application/json" }
                 }).then((response) => response.json())
                 .then((data) => {
                    setCarros(data?.carros);  // Já recebe os carros com as imagens
                 }).catch((error) => console.log(error))
                
             } catch(err){
                 console.log(err)
             }
         }

        fetchCarro()
    }, [])



    const imagens_empresa = [
        {id: '1', href: '/estoque', image: historiaImg},
        {id: '2', href: 'https://www.google.com/maps/place/Lago+Multimarcas/@-22.9325444,-47.0842597,17z/data=!3m2!4b1!5s0x94c8c8e16963cb8b:0x80bac6443a781cef!4m6!3m5!1s0x94c8c8e169bca735:0xdbe407be9b9aa3ee!8m2!3d-22.9325494!4d-47.0816848!16s%2Fg%2F1q62pxwj9?entry=ttu&g_ep=EgoyMDI0MDkxNS4wIKXMDSoASAFQAw%3D%3D',image: enderecoImg},
        {id: '3', href: '/emprestimo', image: emprestimoImg},
        {id: '4', href: '/contato', image: contatoImagem},
        {id: '5', href: '/estoque', image: historiaImg},
        {id: '6', href: 'https://www.google.com/maps/place/Lago+Multimarcas/@-22.9325444,-47.0842597,17z/data=!3m2!4b1!5s0x94c8c8e16963cb8b:0x80bac6443a781cef!4m6!3m5!1s0x94c8c8e169bca735:0xdbe407be9b9aa3ee!8m2!3d-22.9325494!4d-47.0816848!16s%2Fg%2F1q62pxwj9?entry=ttu&g_ep=EgoyMDI0MDkxNS4wIKXMDSoASAFQAw%3D%3D',image: enderecoImg},
        {id: '7', href: '/emprestimo', image: emprestimoImg},
        {id: '8', href: '/contato', image: contatoImagem}

    ]

    function carrosFiltradosFunc() {
        return carros?.map((carro, index) => (
            <div key={index} className="container-all-cars-home">
                <div className="grid-style">
                    <div className="delete-carro"></div>
    
                    <a className="link-carro-href" href={`/detalhe-carros?&id=${carro.ID}&marca=${carro.Marca}&motor=${carro.Motor}`}>
                        <div className="imagem-content-ul">
                            {/* Agora as imagens já estão associadas ao carro */}
                            {carro?.Imagens?.map((imagem, index2) => (
                                <img key={index2} src={imagem.imagem_principal} alt="Imagem Principal do Carro" />
                            ))}
                        </div>
    
                        <div className="info-content-ul margin-top-0-770px margin-top-10px-300px">
                            <div className="info-content-center align-start">
                                <div className="info-carro-outros">
                                        <div className="info-carro-marca-modelo">
                                        <div className="info-carro-ano-cor"><span> {carro.Ano} - {carro.Cor}</span></div>
                                        <div className="info-carro-marca-outros"><span> {carro.Marca} - {carro.Modelo}</span></div>
                                        <div className="info-motor-combustivel-carroceria"><span> {carro.Motor}  {carro.Combustivel}  {carro.Carroceria}</span></div>
                                        <div className="info-kilometragem"><span> {carro.Kilometragem} KM</span></div>
                                        <div className="info-preco">{Number(carro.Valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                                        <div className="info-localizacao"><span>Campinas-SP</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        ));
    }


    return(
        <div className="empresa-all-container">
            <div className="empresa-images-informacoes">
            <Swiper
                slidesPerView={1}
                slidesPerGroup={1} 

                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                pagination={{clickable: true}}
                navigation
                speed={1000}
                loop={true}
                
                style={{
                    "--swiper-pagination-color": "#000",
                    "--swiper-navigation-color": "#000",
                  }}
            >
                {imagens_empresa.map((item) => (
                    <SwiperSlide key={item.id}>
                        <a href={item.href}><img src={item.image} alt="imagens"/></a>
                    </SwiperSlide>
                ))}
            </Swiper>
            </div>

            <div className="empresa-carros-disponiveis">
                <span>Confira algumas opções que temos</span>
            </div>

            <div className="empresa-carros-demostracao-700px-menos">
                <div className="empresa-carros-demostracao-700px-menos-centralized">
                    {carrosFiltradosFunc()}
                </div>
                
            </div>
            
            <div className="empresa-carros-demonstracao">
                {carrosFiltradosFunc()}
            </div>

            <a className="conferir-veiculos-butao" href="/estoque">Mais Veículos</a>
        </div>
    )
}

export default Empresa;