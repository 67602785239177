import React from "react";
import { useState } from "react";
import './style.css'

// Importes destinado as imagens
import menu from '../assets/menu.png'
import logo from '../assets/logoMultiMarcas.jpeg'
import logoWhats from '../assets/logoWhats.png'
import logoInsta from '../assets/Instagram_icon.png'
import logoYout from '../assets/youtube-logo.png'


import iconWhats from '../assets/whatsappIcon.jpeg'
import iconMail from '../assets/emailIcon2.jpeg'
// fechamento de import de imagens

function Header(){
    const [menuOpen, setMenuOpen] = useState(false);

    function openAndClose(){
        if(menuOpen){
            setMenuOpen(false)
        }else{
            setMenuOpen(true);
        }
    }

    function close(){
        setMenuOpen(false)
    }


    return(
        <div className="header-all-container">
            <div className="header-container-principal">
                <div className="header-left-container">
                    <a href="/"><img src={logo} alt="lago multimarcas"/></a>
                </div>
                <div className="header-right-container">
                    
                    <ul className="header-right-container-800px">
                        <ul>
                            <li><a href="/">EMPRESA</a></li>
                            <li><a href="/estoque">ESTOQUE</a></li>
                            <li><a href="/emprestimo">FINANCIAMENTO</a></li>
                            <li><a href="/contato">CONTATO</a></li>
                        </ul>
                    </ul>
                    <img onClick={openAndClose} src={menu} alt="menu"/>
                </div>
                <div className="header-contato-800px">
                    <a href="https://web.whatsapp.com/send?phone=1932316655" target="blank"><img src={logoWhats}/></a>
                    <a href="https://www.instagram.com/lagomulti/" target="blank"><img src={logoInsta}/></a>
                    <a href="https://www.youtube.com/@LagoMultimarcas" target="blank"><img className="youtube-logo" src={logoYout}/></a>

                </div>
            </div>

            {menuOpen && (
                <div className={`header-menu-open-mobile ${menuOpen ? 'open' : ''}`}>
                    <ul className="header-menu-open-mobile-ul">
                        <li><a href="/">EMPRESA</a></li>
                        <li><a href="/estoque">ESTOQUE</a></li>
                        <li><a href="/emprestimo">FINANCIAMENTO</a></li>
                        <li><a href="/contato">CONTATO</a></li>
                        <li className="icon-contato"><img src={iconMail} alt="email"/><span className="contato-content">contato@lagomultimarcas.com.br</span></li>
                        <li className="icon-contato-whats"><img src={iconWhats} alt="whatsapp"/> <span><a href="https://web.whatsapp.com/send?phone=1932316655" target="blank" className="color-black margin-left-0">(19) 3231-6655</a></span></li>


                    </ul>
                </div>
            )}

            
        </div>

        
    )
}

export default Header;

